import React from 'react';
import Layout from '../../../components/layout';
import Vacancy from '../../../components/shared-components/vacancy';

const SalesDevRep = () => {
  return (
    <Layout>
      <Vacancy
        division="Sales & Marketing"
        vacancy="Sales Development Representative (SDR) (English)"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={
          <p>
            We are looking for an ambitious Sales Development Representative (SDR) to find and engage with potential
            customers who can benefit from our products and services.
          </p>
        }
        jobResponsibilities={
          <>
            <p className="secondary-text mb-0">Working in the sales & marketing team to:</p>
            <ul>
              <li className="secondary-text">
                Identify and research companies and people to target via <a href="https://hexomatic.com/">Hexomatic</a>,
                LinkedIn, and lead databases
              </li>
              <li className="secondary-text">Networking on LinkedIn to connect with decision-makers</li>
              <li className="secondary-text">Crafting icebreakers and cold email campaigns</li>
              <li className="secondary-text">Schedule and perform product demos/calls with interested leads</li>
              <li className="secondary-text">
                Follow up with potential customers who didn’t book a demo or didn’t attend the demo.
              </li>
            </ul>
          </>
        }
        qualifications={
          <>
            <ul>
              <li className="secondary-text">Fluent in US English (Office communications and work are in English)</li>
              <li className="secondary-text">2+ years of work experience in sales</li>
              <li className="secondary-text">Great writing/speaking skills and attention to detail</li>
              <li className="secondary-text">
                Experience using LinkedIn (Sales Navigator), and cold outreach email software (for ex: Lemlist, Hubspot,
                Reply.io, Snov.io etc.)
              </li>
              <li className="secondary-text">Passion for sales and learning new things</li>
              <li className="secondary-text">
                Self-starter, always looking for opportunities to improve, grow and share ideas.
              </li>
            </ul>
            <p className="secondary-text">*Please provide examples of past campaigns/projects in English.</p>
          </>
        }
      />
    </Layout>
  );
};

export default SalesDevRep;
